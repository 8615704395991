import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import Lightbox from "react-images-with-video";
import {
  ADUImageSelection,
  ADUChatMap,
  ADUImageCrop,
  ADUBedBathSelection,
} from "./aduinputcomps";
import { Button as AntdButton, Tooltip } from "antd";
import {
  defaultMessage,
  handleConversation,
  handleEstimate,
} from "./aduchatutils";
import { InlineWidget } from "react-calendly";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import aduCahtBotIcon from "../assets/icons/adu-chatbot-icon.svg";
import aduChatBotIcon from "../assets/icons/aduchat-bot-icon.svg";
import aduChatUserIcon from "../assets/icons/aduchat-user-icon.svg";
import attachedAduImage from "../assets/images/attached-adu-image.svg";
import dettachedAduImage from "../assets/images/dettached-adu-image.svg";
import useScreenWidth from "../utils/useScreenWidth";

export const ADUModalBody = ({
  typing,
  setTyping,
  messages = [],
  setMessages,
  sessionId,
  chatWindowRef = null,
  modalBodyHeight = 0,
  selectedLocation,
  setSelectedLocation,
  viewport,
  setViewport,
  mapCompInput,
  setMapCompInput,
  aduImages,
  setAduImages,
  selectedImage,
  setSelectedImage,
  setCompletedCrop,
  imageForCrop,
  area,
  setArea,
  setImageForCrop,
  bedrooms,
  setBedrooms,
  bathrooms,
  setBathrooms,
  bedroomOptions,
  bathroomOptions,
  addressFromUrl,
}) => {
  const usedKeys = new Set();
  let lastMessage = messages[messages?.length - 1];

  useEffect(() => {
    const scrollToBottom = () => {
      if (chatWindowRef.current) {
        chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
      }
    };

    // Initial scroll
    scrollToBottom();

    setTimeout(() => scrollToBottom(), 100);
  }, [messages, chatWindowRef]);

  const handleModalClick = () => {
    if (selectedImage) {
      setSelectedImage(null);
    }
  };

  const width = useScreenWidth();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
      onClick={handleModalClick} // Add this line
    >
      <div
        className="custom-scrollbar"
        style={{
          flexGrow: 1,
          overflowY: "auto",
          overflowX: "hidden",
          padding: width > 768 ? 20 : 8,
          paddingBottom: "90px",
          display: "flex",
          flexDirection: "column",
          gap: 10,
        }}
        ref={chatWindowRef}
      >
        {lastMessage?.showAddress ? (
          // <ADUMessageCardWrapper sender="system">
          <ADUChatMap
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            viewport={viewport}
            setViewport={setViewport}
            mapCompInput={mapCompInput}
            setMapCompInput={setMapCompInput}
            setTyping={setTyping}
            sessionId={sessionId}
            setMessages={setMessages}
            setAduImages={setAduImages}
            setImageForCrop={setImageForCrop}
            bedrooms={bedrooms}
            bathrooms={bathrooms}
            addressFromUrl={addressFromUrl}
          />
        ) : (
          // </ADUMessageCardWrapper>
          messages?.map((message, index) => {
            let randomKey;
            // Generate a random key until it's unique
            do {
              randomKey = Math.floor(Math.random() * 1000000);
            } while (usedKeys.has(randomKey));

            // Add the key to the set of used keys
            usedKeys.add(randomKey);
            if (message?.content || message?.showImgSlctnMsg) {
              return (
                <ADUMessageCard
                  typing={typing}
                  setTyping={setTyping}
                  key={randomKey}
                  messages={messages}
                  setMessages={setMessages}
                  sessionId={sessionId}
                  message={message}
                  index={index}
                  length={messages?.length}
                  setAduImages={setAduImages}
                  setImageForCrop={setImageForCrop}
                  bedrooms={bedrooms}
                  bathrooms={bathrooms}
                  bedroomOptions={bedroomOptions}
                  bathroomOptions={bathroomOptions}
                />
              );
            }
          })
        )}

        {lastMessage?.showBedBathSelection && (
          <ADUMessageCardWrapper sender="system">
            <ADUBedBathSelection
              bedrooms={bedrooms}
              setBedrooms={setBedrooms}
              bathrooms={bathrooms}
              setBathrooms={setBathrooms}
              bedroomOptions={bedroomOptions}
              bathroomOptions={bathroomOptions}
            />
          </ADUMessageCardWrapper>
        )}

        {lastMessage?.showImage && (
          <ADUMessageCardWrapper sender="system">
            <ADUImageSelection
              aduImages={aduImages}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
              sessionId={sessionId}
              setAduImages={setAduImages}
              bedrooms={bedrooms}
              bathrooms={bathrooms}
            />
          </ADUMessageCardWrapper>
        )}

        {lastMessage?.showCropImage && (
          <ADUMessageCardWrapper sender="system">
            <ADUImageCrop
              sessionId={sessionId}
              imageUrl={imageForCrop}
              setCompletedCrop={setCompletedCrop}
              area={area}
              setArea={setArea}
            />
          </ADUMessageCardWrapper>
        )}
      </div>
    </div>
  );
};

export const ADUMessageCard = ({
  typing,
  setTyping,
  message,
  setMessages,
  sessionId,
  index,
  length,
  setAduImages,
  setImageForCrop,
  bedrooms,
  bathrooms,
  bedroomOptions,
  bathroomOptions,
}) => {
  const [imageIndex, setImageIndex] = useState(0);
  const lightboxphotos = [
    {
      src: message.isImage ? message.content : "",
      type: null,
    },
  ];
  const [LightboxState, setLightboxState] = useState({
    lightBoxOpen: false,
    currentIndex: 0,
    count: lightboxphotos.length,
    photos: lightboxphotos,
    lightBoxPhotos: lightboxphotos,
    isLoading: false,
    hasMore: true,
  });

  const onClickPhoto = (index) => {
    setLightboxState((prev) => ({
      ...prev,
      lightBoxOpen: true,
      currentIndex: 0,
    }));
    setImageIndex(index);
  };

  const onClickPrevious = () => {
    setLightboxState((prev) => ({
      ...prev,
      currentIndex: prev.currentIndex - 1,
    }));
  };

  const onClickNext = () => {
    setLightboxState((prev) => ({
      ...prev,
      currentIndex: prev.currentIndex + 1,
    }));
  };

  const handleClickButton = (value) => {
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages];
      if (newMessages.length > 0) {
        // Edit the last message
        newMessages[newMessages.length - 1] = {
          ...newMessages[newMessages.length - 1],
          showButtons: false,
          btnContent: null,
        };
      }
      return newMessages;
    });

    handleConversation({
      setTyping,
      value,
      sessionId,
      setMessages,
      setAduImages,
      setImageForCrop,
      bedrooms,
      bathrooms,
    });
  };

  const handleClickAttachDetach = (value, btnContent) => {
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages];
      if (newMessages.length > 0) {
        // Edit the last message
        newMessages[newMessages.length - 1] = {
          ...newMessages[newMessages.length - 1],
          showButtons: false,
          btnContent: btnContent,
          isAttachDetach: true,
          content: value,
        };
      }
      return newMessages;
    });

    handleConversation({
      setTyping,
      value,
      sessionId,
      setMessages,
      setAduImages,
      setImageForCrop,
      bedrooms,
      bathrooms,
    });
  };

  const width = useScreenWidth();
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <ADUMessageCardWrapper sender={message?.sender} message={message}>
        <div
          style={{
            width: "100%",
          }}
        >
          <div
            key={index}
            style={{
              width: "100%",
              display: "flex",
              flexDirection:
                message?.showButtons || message?.showCalendly
                  ? "column"
                  : "row",
              justifyContent: message?.sender === "user" ? "end" : "start",
              gap: message?.isAttachDetach ? (width > 768 ? 20 : 10) : 10,
            }}
          >
            {!message?.showCalendly && (
              <div
                className={`${
                  index === length - 1 ? "adu-fadein-animation" : ""
                }
          
          `}
                style={{
                  width: "100%",
                  maxWidth: "max-content",
                  minHeight: width > 768 ? "70px" : "50px",
                  padding:
                    message?.isBedBath ||
                    message?.isAttachDetach ||
                    message?.isImage ||
                    message?.showImgSlctnMsg ||
                    message?.isIframe
                      ? 0
                      : width > 768
                      ? 20
                      : 10,
                  color: "#504D3F",
                  backgroundColor:
                    message?.showImgSlctnMsg ||
                    message?.isBedBath ||
                    message?.isAttachDetach ||
                    message?.isIframe
                      ? "transparent"
                      : message?.sender === "user"
                      ? "#E5E1D4"
                      : "#FFFFFF80",
                  border:
                    message?.isBedBath ||
                    message?.isAttachDetach ||
                    message?.isImage ||
                    message?.showImgSlctnMsg ||
                    message?.isIframe
                      ? "0px"
                      : message?.sender === "user"
                      ? "1px solid #CECECE"
                      : "1px solid #DBDBDB",
                  borderRadius:
                    message?.sender === "user"
                      ? "12px 12px 0px 12px"
                      : "12px 12px 12px 0px",
                  boxShadow:
                    message?.isBedBath ||
                    message?.isAttachDetach ||
                    message?.isImage ||
                    message?.showImgSlctnMsg ||
                    message?.isIframe
                      ? "none"
                      : "0px 0px 25px 0px #C1C1C140",
                  fontWeight: 400,
                  fontSize: width > 768 ? 16 : 14,
                  lineHeight: "25px",

                  // color: message?.sender === "user" ? "black" : "white",
                }}
              >
                {message?.showImgSlctnMsg ? (
                  <ADUMessageImageSlctn
                    selectedImage={message.selectedImage}
                    aduImages={message.aduImages}
                  />
                ) : message?.isBedBath ? (
                  <ADUIsBedBathComp
                    value={message?.content}
                    bedroomOptions={bedroomOptions}
                    bathroomOptions={bathroomOptions}
                  />
                ) : message?.isAttachDetach ? (
                  <ADUIsAttachDetach
                    content={message?.content}
                    btnContent={message?.btnContent}
                  />
                ) : message?.isImage ? (
                  <img
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickPhoto(index);
                    }}
                    src={message?.content}
                    alt="adu"
                    style={{
                      objectFit: "cover",
                      aspectRatio: "square",
                      minHeight: "250px",
                      minWidth: "250px",
                      maxHeight: "350px",
                      maxWidth: "350px",
                      height: "100%",
                      width: "100%",
                      borderRadius:
                        message?.sender === "user"
                          ? "9px 9px 0px 9px"
                          : "9px 9px 9px 0px",
                    }}
                  />
                ) : typeof message?.content === "string" ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(message?.content),
                    }}
                    className="bg-white w-fit"
                  />
                ) : (
                  <div className="bg-white w-fit">{message?.content}</div>
                )}
              </div>
            )}

            {LightboxState.lightBoxOpen &&
              imageIndex === index &&
              message.isImage && (
                <Lightbox
                  images={LightboxState.lightBoxPhotos}
                  isOpen={LightboxState.lightBoxOpen}
                  currentImage={LightboxState.currentIndex}
                  onClickPrev={onClickPrevious}
                  onClickNext={onClickNext}
                  onClose={(e) =>
                    setLightboxState({ ...LightboxState, lightBoxOpen: false })
                  }
                />
              )}

            {message?.showCalendly && (
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                  width: "100%",
                  alignItems: "start",
                  justifyContent: "start",
                }}
              >
                <ADUShowCalendly />
              </div>
            )}
            {message?.showButtons && (
              <div
                className={index === length - 1 ? "adu-fadein-animation" : ""}
                style={{
                  display: "flex",
                  flexWrap:
                    message?.isAttachDetach ||
                    message?.btnContent?.some(
                      (str) =>
                        str.toLowerCase().includes("attached") ||
                        str.toLowerCase().includes("detached")
                    )
                      ? "nowrap"
                      : width > 768
                      ? "nowrap"
                      : "wrap",
                  gap: message?.isAttachDetach ? (width > 768 ? 20 : 10) : 10,
                  marginTop: "10px",
                }}
              >
                {message?.isIframe
                  ? message?.btnContent?.map((value, index) => {
                      return (
                        <AntdButton
                          key={index}
                          className="btn-gradient-border"
                          style={{
                            // backgroundColor:
                            //   message?.sender === "user" ? "#E3E3E3" : "#F5A724",
                            borderRadius: 20,
                            // color: "white",
                            padding: "4px 12px",
                            height: "unset",
                          }}
                          onClick={() => {
                            if (
                              value ===
                              "I really like it! Show me the cost estimation"
                            ) {
                              handleEstimate({
                                sessionId,
                                setTyping,
                                setMessages,
                              });
                            } else {
                              setMessages((prevMessages) => {
                                return [
                                  ...prevMessages,
                                  {
                                    ...defaultMessage,
                                    showCropImage: true,
                                  },
                                ];
                              });
                            }
                          }}
                        >
                          {value}
                        </AntdButton>
                      );
                    })
                  : message?.btnContent?.map((value, index) => {
                      const isDetached = value
                        .toLowerCase()
                        .includes("detached");
                      const isAttached = value
                        .toLowerCase()
                        .includes("attached");
                      return isAttached || isDetached ? (
                        <AntdButton
                          key={index}
                          style={{
                            backgroundColor: "#FFFFFF80",
                            //   message?.sender === "user" ? "#E3E3E3" : "#F5A724",
                            borderRadius: 12,
                            // color: "white",
                            padding: "12px 0 0 0",
                            height: "unset",
                            display: "flex",
                            flexDirection: "column",
                            gap: 5,
                            width: "100%",
                            maxWidth: 452,
                            maxHeight: 452,
                            overflow: "hidden",
                          }}
                          onClick={() =>
                            handleClickAttachDetach(value, message?.btnContent)
                          }
                        >
                          <div
                            style={{
                              fontSize: width > 768 ? 16 : 14,
                              fontWeight: 600,
                              padding: "5px 20px",
                            }}
                          >
                            {value}
                          </div>
                          <div
                            style={{
                              display: width > 768 ? "block" : "none",
                              fontSize: 14,
                              padding: "5px 20px",
                              whiteSpace: "normal",
                              overflowWrap: "break-word",
                              textAlign: "left",
                            }}
                          >
                            {isDetached
                              ? "A separate dwelling unit not attached to the main house, providing privacy and independence."
                              : "A unit that shares a wall with the main house, offering easy access and shared utilities."}
                          </div>
                          <img
                            src={
                              isAttached ? attachedAduImage : dettachedAduImage
                            }
                            alt="adu"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </AntdButton>
                      ) : (
                        <AntdButton
                          key={index}
                          className="btn-gradient-border"
                          style={{
                            // backgroundColor:
                            //   message?.sender === "user" ? "#E3E3E3" : "#F5A724",
                            borderRadius: 20,
                            // color: "white",
                            padding: "4px 12px",
                            height: "unset",
                          }}
                          onClick={() => handleClickButton(value)}
                        >
                          {value}
                        </AntdButton>
                      );
                    })}
              </div>
            )}
            {message?.showCalendly && (
              <CalendlyPopupwithBtns
                message={message}
                index={index}
                setMessages={setMessages}
              />
            )}
          </div>
        </div>
      </ADUMessageCardWrapper>

      {/* <ADUShowCalendly /> */}
      {/* <CalendlyPopup message={message} index={index} length={length} /> */}

      {typing && index === length - 1 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            marginTop: 20,
          }}
          className=""
        >
          <div
            className="adu-fadein-animation"
            style={{
              maxWidth: 350,
              padding: 15,
              backgroundColor: "white",
              borderRadius: "20px 20px 20px 0px",
              color: "white",
              border: "1px solid #DBDBDB",
            }}
          >
            <div className="aduchat-typing-loader" />
          </div>
        </div>
      )}
    </div>
  );
};

export const ADUMessageImageSlctn = ({ aduImages, selectedImage }) => {
  const lightboxphotos = aduImages.map((values) => {
    return {
      src: values.url,
      type: null,
    };
  });

  const [state, setState] = useState({
    lightBoxOpen: false,
    currentIndex: 0,
    count: lightboxphotos.length,
    photos: lightboxphotos,
    lightBoxPhotos: lightboxphotos,
    isLoading: false,
    hasMore: true,
  });

  const onClickPhoto = (index) => {
    setState((prev) => ({
      ...prev,
      lightBoxOpen: true,
      currentIndex: index,
    }));
  };

  const onClickPrevious = () => {
    setState((prev) => ({
      ...prev,
      currentIndex: prev.currentIndex - 1,
    }));
  };

  const onClickNext = () => {
    setState((prev) => ({
      ...prev,
      currentIndex: prev.currentIndex + 1,
    }));
  };

  const handleDownload = (image) => {
    const link = document.createElement("a");
    link.href = image.url; // The image URL
    link.download = `ADU-${image.id}.jpg`; // Provide a default filename
    link.click();
  };

  return (
    <div className="adu-fadein-animation" style={{ color: "black" }}>
      <div
        style={{
          display: "grid",
          gap: 5,
          gridTemplateColumns: "1fr auto", // Two columns: images and legend
          width: "100%",
          // maxWidth: "450px",
          marginTop: 10,
        }}
      >
        {/* Image Grid */}
        <div
          style={{
            display: "grid",
            gap: 5,
            gridTemplateColumns: "repeat(2, 1fr)",
            gridTemplateRows: "repeat(2, auto)",
            // maxHeight: "350px",
          }}
        >
          {(aduImages || [])?.map((image, index) => (
            <div
              key={image.id}
              style={{
                background: "#FFFFFF80",
                position: "relative",
                border:
                  image?.id === selectedImage?.id
                    ? "2px solid #826DFB"
                    : "2px solid lightgray",
                borderRadius: 8,
                padding: 2,
                opacity: image?.id === selectedImage?.id ? 0.7 : 1,
              }}
            >
              <img
                onClick={(e) => {
                  e.stopPropagation();
                }}
                src={image.url}
                alt={`ADU ${image.id}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: 8,
                }}
              />
              <div
                onClick={() => onClickPhoto(index)}
                className="eye-btn"
                style={{
                  position: "absolute",
                  top: 5,
                  right: 30,
                  backgroundColor: "transparent",
                  borderRadius: "50%",
                  height: 18,
                  width: 18,
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 2,
                }}
              >
                <EyeOutlined />
              </div>
              <div
                onClick={() => handleDownload(image)}
                className="eye-btn"
                style={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  backgroundColor: "transparent",
                  borderRadius: "50%",
                  height: 18,
                  width: 18,
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 2,
                }}
              >
                <DownloadOutlined />
              </div>

              <div
                style={{
                  position: "absolute",
                  bottom: 5,
                  right: 5,
                  height: 18,
                  width: "max-content",
                  zIndex: 2,
                  fontWeight: "bold",
                }}
              >
                {image.text || ""}
              </div>
            </div>
          ))}
        </div>

        {/* Legend Section */}
        <LengendComp />
        {/* Lightbox Modal */}
        {state.lightBoxOpen && (
          <Lightbox
            images={state.lightBoxPhotos}
            isOpen={state.lightBoxOpen}
            currentImage={state.currentIndex}
            onClickPrev={onClickPrevious}
            onClickNext={onClickNext}
            onClose={(e) => setState({ ...state, lightBoxOpen: false })}
          />
        )}
      </div>
    </div>
  );
};

export const CalendlyLinkwithBtns = ({ message, index, setMessages }) => {
  const width = useScreenWidth();
  return (
    <div
      style={{
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
        gap: 10,
        width: "100%",
        alignItems: "start",
        justifyContent: "start",
      }}
    >
      <div
        style={{
          color: "#504D3F",
          fontSize: width > 768 ? 16 : 14,
          lineHeight: width > 768 ? "28px" : "22px",
        }}
      >
        According to our calculations, there’s a more cost-effective plan
        available for your ADU. Would you like to learn about the most
        budget-friendly option?
      </div>
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          flexWrap: "wrap",
          gap: 10,
          width: "100%",
          alignItems: "start",
          justifyContent: "start",
        }}
      >
        <a
          href={message?.calendlyLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <AntdButton
            key={index}
            className="calendly-btn"
            style={{
              borderRadius: 20,
              padding: "4px 12px",
              height: "unset",
            }}
          >
            Schedule a Meeting
          </AntdButton>
        </a>
        <AntdButton
          key={index}
          className="btn-gradient-border"
          style={{
            borderRadius: 20,
            padding: "4px 12px",
            height: "unset",
          }}
          onClick={() =>
            setMessages((prevMessages) => {
              return [
                ...prevMessages,
                {
                  ...defaultMessage,
                  showCropImage: true,
                },
              ];
            })
          }
        >
          Go back to the previous step
        </AntdButton>
        <AntdButton
          key={index}
          className="btn-gradient-border"
          style={{
            borderRadius: 20,
            padding: "4px 12px",
            height: "unset",
          }}
          onClick={() => window.location.reload()}
        >
          Start over
        </AntdButton>
      </div>
    </div>
  );
};
export const CalendlyPopupwithBtns = ({ message, index, setMessages }) => {
  const [isOpen, setIsOpen] = useState(false);
  const width = useScreenWidth();

  const handleTextClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div
      style={{
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
        gap: 10,
        width: "100%",
        alignItems: "start",
        justifyContent: "start",
      }}
    >
      <div
        style={{
          color: "#504D3F",
          fontSize: width > 768 ? 16 : 14,
          lineHeight: width > 768 ? "28px" : "22px",
        }}
      >
        According to our calculations, there’s a more cost-effective plan
        available for your ADU. Would you like to learn about the most
        budget-friendly option?
      </div>
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          flexWrap: "wrap",
          gap: 10,
          width: "100%",
          alignItems: "start",
          justifyContent: "start",
        }}
      >
        <AntdButton
          key={index}
          className="calendly-btn"
          style={{
            borderRadius: 20,
            padding: "4px 12px",
            height: "unset",
          }}
          onClick={handleTextClick}
        >
          Schedule a Meeting
        </AntdButton>

        <AntdButton
          key={index}
          className="btn-gradient-border"
          style={{
            borderRadius: 20,
            padding: "4px 12px",
            height: "unset",
          }}
          onClick={() =>
            setMessages((prevMessages) => {
              return [
                ...prevMessages,
                {
                  ...defaultMessage,
                  showCropImage: true,
                },
              ];
            })
          }
        >
          Go back to the previous step
        </AntdButton>
        <AntdButton
          key={index}
          className="btn-gradient-border"
          style={{
            borderRadius: 20,
            padding: "4px 12px",
            height: "unset",
          }}
          onClick={() => window.location.reload()}
        >
          Start over
        </AntdButton>
        {isOpen && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 100000,
            }}
          >
            <div
              style={{
                width: "90%",
                maxWidth: "600px",
                height: "80%",
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
                position: "relative",
              }}
            >
              <button
                onClick={handleClose}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  background: "transparent",
                  border: "none",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              >
                &times;
              </button>
              <InlineWidget
                className="custom-scrollbar"
                url={`${message?.calendlyLink}`}
                styles={{ height: "100%" }}
                pageSettings={{
                  backgroundColor: "ffffff",
                  hideEventTypeDetails: false,
                  hideLandingPageDetails: false,
                  primaryColor: "00a2ff",
                  textColor: "4d5055",
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const ADUMessageCardWrapper = ({ children, sender, message }) => {
  const width = useScreenWidth();
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "start",
        justifyContent: "start",
        flexDirection: sender === "user" ? "row-reverse" : "row",
        gap: width > 768 ? 10 : 5,
        marginTop: 10,
      }}
    >
      <div
        style={{
          boxShadow: "0px 0px 25px 0px #C1C1C140",
          border: "1px solid #DBDBDB",
          background: "#FFFFFF80",
          width: width > 768 ? "70px" : "50px",
          minWidth: width > 768 ? "70px" : "50px",
          height: width > 768 ? "70px" : "50px",
          padding: width > 768 ? "7px" : "4px",
          gap: "0px",
          borderRadius: "12px",
          opacity: "0px",
        }}
      >
        <img
          src={sender === "user" ? aduChatUserIcon : aduChatBotIcon}
          alt="adu"
          style={{
            height: "100%",
            width: "100%",
            borderRadius: "50%",
          }}
        />
      </div>

      {children}
    </div>
  );
};

export const LengendComp = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 8,
        alignItems: "flex-start",
        width: "100%",
        maxWidth: "max-content",
        paddingTop: 10,
      }}
    >
      <Tooltip
        title={
          <div
            style={{
              height: "4rem",
              width: "4rem",
              borderRadius: 4,
              backgroundColor: "#F38383",
            }}
          ></div>
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            cursor: "pointer",
          }}
        >
          <div
            style={{
              width: 15,
              height: 15,
              backgroundColor: "#F38383",
              borderRadius: 4,
            }}
          />
          <span>Living Room</span>
        </div>
      </Tooltip>
      <Tooltip
        title={
          <div
            style={{
              height: "4rem",
              width: "4rem",
              borderRadius: 4,
              backgroundColor: "#D2D2D2",
            }}
          ></div>
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            cursor: "pointer",
          }}
        >
          <div
            style={{
              width: 15,
              height: 15,
              backgroundColor: "#D2D2D2",
              borderRadius: 4,
            }}
          />
          <span>Bathroom</span>
        </div>
      </Tooltip>
      <Tooltip
        title={
          <div
            style={{
              height: "4rem",
              width: "4rem",
              borderRadius: 4,
              backgroundColor: "#FFE09E",
            }}
          ></div>
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            cursor: "pointer",
          }}
        >
          <div
            style={{
              width: 15,
              height: 15,
              backgroundColor: "#FFE09E",
              borderRadius: 4,
            }}
          />
          <span>Bedroom</span>
        </div>
      </Tooltip>
    </div>
  );
};

export const ADUIsBedBathComp = ({
  value,
  bedroomOptions,
  bathroomOptions,
}) => {
  const [beds, baths] = value.split("|");
  const width = useScreenWidth();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: width > 768 ? 20 : 10,
        padding: width > 768 ? "2rem" : "1.5rem",
        maxWidth: "max-content",
        background: "#FFFFFF80",
        border: "1px solid #CECECE",
        borderRadius: "12px",
        boxShadow: "0px 0px 25px 0px #C1C1C140",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: width > 768 ? 20 : 10,
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              marginBottom: 10,
            }}
          >
            <div
              style={{ fontWeight: "bold", fontSize: width > 768 ? 18 : 14 }}
            >
              Bedrooms
            </div>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {(bedroomOptions || []).map((option, index) => (
              <div
                key={option}
                style={{
                  border: ` ${
                    beds === option ? "1.5px solid #826DFB" : "1px solid #ccc"
                  }`,
                  backgroundColor: "#fff",
                  color: "#504D3F",
                  fontWeight: 400,
                  padding: width > 768 ? "10px 20px" : "8px 15px",
                  fontSize: width > 768 ? 18 : 14,
                }}
              >
                {option}
              </div>
            ))}
          </div>
        </div>

        <div>
          <label
            style={{
              fontWeight: "bold",
              fontSize: width > 768 ? 18 : 14,
              marginBottom: 10,
              display: "block",
            }}
          >
            Bathrooms
          </label>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {(bathroomOptions || []).map((option, index) => (
              <div
                key={option}
                style={{
                  padding: width > 768 ? "10px 20px" : "8px 15px",
                  border: ` ${
                    baths === option ? "1.5px solid #826DFB" : "1px solid #ccc"
                  }`,
                  backgroundColor: "#fff",
                  color: "#504D3F",
                  fontWeight: 400,
                  fontSize: width > 768 ? 18 : 14,
                }}
              >
                {option}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ADUIsAttachDetach = ({ content, btnContent }) => {
  const width = useScreenWidth();
  return (
    <div
      style={{
        display: "flex",
        gap: width > 768 ? 20 : 10,
        width: "100%",
      }}
    >
      {btnContent?.map((value, index) => {
        const isDetached = value.toLowerCase().includes("detached");
        const isAttached = value.toLowerCase().includes("attached");
        return (
          (isAttached || isDetached) && (
            <div
              key={index}
              style={{
                // backgroundColor:
                //   message?.sender === "user" ? "#E3E3E3" : "#F5A724",
                borderRadius: 20,
                // color: "white",
                padding: "12px 0 0 0",
                height: "unset",
                display: "flex",
                flexDirection: "column",
                gap: 5,
                width: "100%",
                maxWidth: 452,
                maxHeight: 452,
                overflow: "hidden",
                backgroundColor: "#FFFFFF80",
                border:
                  content === value
                    ? "1.5px solid #826DFB"
                    : "1.5px solid #dbdbdb",
                boxShadow: "0px 0px 25px 0px #C1C1C140",
              }}
            >
              <div
                style={{
                  fontSize: width > 768 ? 16 : 14,
                  fontWeight: 600,
                  padding: "5px 20px",
                }}
              >
                {value}
              </div>
              <div
                style={{
                  display: width > 768 ? "block" : "none",
                  fontSize: 14,
                  padding: "5px 20px",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                  textAlign: "left",
                }}
              >
                {isDetached
                  ? "A separate dwelling unit not attached to the main house, providing privacy and independence."
                  : "A unit that shares a wall with the main house, offering easy access and shared utilities."}
              </div>
              <img
                src={isAttached ? attachedAduImage : dettachedAduImage}
                alt="adu"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          )
        );
      })}
    </div>
  );
};

export const ADUShowCalendly = () => {
  const calendlycardStyles = {
    card: {
      background: "#FFFFFF80",
      border: "1px solid #CECECE",
      borderRadius: "12px",
      padding: "20px 30px",
      boxShadow: "0px 0px 25px 0px #C1C1C140",
      width: "100%",
      maxWidth: 500,
      color: "#504D3F",
    },
    badge: {
      display: "inline-block",
      backgroundColor: "#ffffff",
      color: "#826DFB",
      fontSize: "14px",
      fontWeight: "600",
      padding: "8px 18px",
      border: "1px solid #DBDBDB",
      borderRadius: "30px",
      marginBottom: "16px",
    },
    details: {
      textAlign: "left",
    },
    lotSize: {
      fontSize: "16px",
      fontWeight: "500",
      color: "#504D3F",
      marginBottom: "16px",
    },
    costContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: 10,
      justifyContent: "space-between",
      marginBottom: "16px",
    },
    costItem: {
      textAlign: "left",
    },
    costTitle: {
      fontSize: "14px",
      color: "#504D3F",
      marginBottom: "4px",
    },
    costValue: {
      fontSize: "40px",
      fontWeight: "bold",
      color: "#504D3F",
      marginBottom: "4px",
    },
    costSubtitle: {
      fontSize: "12px",
      color: "#504D3F",
    },
    disclaimer: {
      fontSize: "12px",
      color: "#504D3F",
      textAlign: "left",
      lineHeight: "1.4",
    },
  };
  const lotSize = "9424 sqft";
  const estimatedArea = "1200 sqft";
  const estimatedCost = "$378,000";
  const estimatedCostFormatted = `$315

`;
  return (
    <div style={calendlycardStyles.card}>
      <div style={calendlycardStyles.badge}>Cost Estimation</div>
      <div style={calendlycardStyles.details}>
        <p style={calendlycardStyles.lotSize}>
          Lot size: <strong>{lotSize}</strong>, Estimated ADU area:{" "}
          <strong>{estimatedArea}</strong>
        </p>
        <div style={calendlycardStyles.costContainer}>
          <div style={calendlycardStyles.costItem}>
            <p style={calendlycardStyles.costTitle}>Estimated total cost</p>
            <p style={calendlycardStyles.costValue}>{estimatedCost}</p>
            <p style={calendlycardStyles.costSubtitle}>
              Based on current market rates
            </p>
          </div>
          <div style={calendlycardStyles.costItem}>
            <p style={calendlycardStyles.costTitle}>Cost per square foot</p>
            <p style={calendlycardStyles.costValue}>{estimatedCostFormatted}</p>
            <p style={calendlycardStyles.costSubtitle}>
              Average construction cost
            </p>
          </div>
        </div>
        <p style={calendlycardStyles.disclaimer}>
          This estimation includes standard construction costs and basic
          finishes. Actual costs may vary based on design choices and site
          conditions.
        </p>
      </div>
    </div>
  );
};
