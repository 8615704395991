import "./App.css";
import ADUChatBot from "./components/aduchatbot";
// import "antd/dist/antd.css";
import 'antd/dist/reset.css';

function App() {
  const url = window.location.href;
  const params = new URLSearchParams(new URL(url).search);
  const addressFromUrl = params.get('address');
  console.log(addressFromUrl)
  return (
    <div className="App">
      <div className="adu-chat-header">Chat with Homie</div>
      {/* Pass the address as a prop to ADUChatBot */}
      <ADUChatBot addressFromUrl={addressFromUrl} />
    </div>
  );
}

export default App;