import React, { useEffect, useRef, useState } from "react";
import { clientPost } from "../utils/apiclient";
import { ADUChatInput } from "./aduinputcomps";
import { ADUModalBody } from "./adumessagecomps";
import {
  apiLink,
  boldNumbersAndSymbols,
  defaultMessage,
  setErrorMessage,
} from "./aduchatutils";
import "./aduchatbot.css";

const ADUChatBot = ({addressFromUrl}) => {
  const [typing, setTyping] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [messages, setMessages] = useState([]);
  console.log("messages", messages);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalBodyHeight, setModalBodyHeight] = useState(0);
  const chatWindowRef = useRef(null);
  const [viewport, setViewport] = useState({
    latitude: 34.052235,
    longitude: -118.243683,
    zoom: 10,
  });
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [mapCompInput, setMapCompInput] = useState("");
  const [bedrooms, setBedrooms] = useState(null);
  const [bathrooms, setBathrooms] = useState(null);
  const [bedroomOptions, setBedroomOptions] = useState([
    "Studio",
    "1",
    "2",
    "3",
  ]);
  const [bathroomOptions, setBathroomOptions] = useState([
    "Any",
    "1",
    "1.5",
    "2",
    "2.5",
    "3",
  ]);
  const [aduImages, setAduImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageForCrop, setImageForCrop] = useState("");
  const [completedCrop, setCompletedCrop] = useState(null);
  const [area, setArea] = useState(null);

  useEffect(() => {
    if (addressFromUrl) {
      console.log("it came")
      setMapCompInput(addressFromUrl);
    } else {
      setMapCompInput("");
    }
  }, [addressFromUrl]);
  //to here
  const handleResetStates = () => {
    setSessionId("");
    setMessages([]);
    setViewport({
      latitude: 34.052235,
      longitude: -118.243683,
      zoom: 10,
    });
    setSelectedLocation(null);
    setMapCompInput("");
    // setAduImages([]);
    setSelectedImage(null);
    // setImageForCrop("");
    setCompletedCrop(null);
  };

  // connect to backend.
  useEffect(() => {
    setTyping(true);
    clientPost(`${apiLink}/connect`, {}, true)
      .then(async (resp) => {
        if (resp.status === 200) {
          const response = await resp.json();
          setSessionId(response.sessionId || "");
          setMessages((prev) => {
            const tempMessages = [
              ...prev,
              {
                ...defaultMessage,
                content: boldNumbersAndSymbols(response.fulfillment_text) || "",
                showAddress: true,
              },
            ];
            return tempMessages;
          });
        }
        setTyping(false);
      })
      .catch((error) => {
        console.error("Error", error);
        setErrorMessage(setMessages);
        setTyping(false);
      });

    if (localStorage.getItem("aduChatAddress")) {
      localStorage.removeItem("aduChatAddress");
    }
    if (localStorage.getItem("selectedAduImage")) {
      localStorage.removeItem("selectedAduImage");
    }
  }, []);

  return (
    <div className="adu-chat-main">
      <ADUModalBody
        typing={typing}
        setTyping={setTyping}
        messages={messages}
        setMessages={setMessages}
        sessionId={sessionId}
        chatWindowRef={chatWindowRef}
        modalBodyHeight={modalBodyHeight}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        viewport={viewport}
        setViewport={setViewport}
        mapCompInput={mapCompInput}
        setMapCompInput={setMapCompInput}
        aduImages={aduImages}
        setAduImages={setAduImages}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        setCompletedCrop={setCompletedCrop}
        imageForCrop={imageForCrop}
        setImageForCrop={setImageForCrop}
        area={area}
        setArea={setArea}
        bedrooms={bedrooms}
        setBedrooms={setBedrooms}
        bathrooms={bathrooms}
        setBathrooms={setBathrooms}
        bedroomOptions={bedroomOptions}
        bathroomOptions={bathroomOptions}
        addressFromUrl= {addressFromUrl}
      />
      <ADUChatInput
        typing={typing}
        setTyping={setTyping}
        sessionId={sessionId}
        messages={messages}
        setMessages={setMessages}
        setSelectedLocation={setSelectedLocation}
        setViewport={setViewport}
        mapCompInput={mapCompInput}
        setMapCompInput={setMapCompInput}
        selectedImage={selectedImage}
        imageForCrop={imageForCrop}
        setImageForCrop={setImageForCrop}
        completedCrop={completedCrop}
        area={area}
        aduImages={aduImages}
        setAduImages={setAduImages}
        bedrooms={bedrooms}
        bathrooms={bathrooms}
        addressFromUrl={addressFromUrl}
      />
    </div>
  );
};

export default ADUChatBot;
