import React, { useEffect, useRef, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import { Tooltip, Button as AntdButton } from "antd";
import { clientGet } from "../utils/apiclient";
import Lightbox from "react-images-with-video";
import NewCrop from "../components/aduNewCrop";
import {
  DownloadOutlined,
  EyeOutlined,
  ReloadOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  defaultMessage,
  generateFloorPlan,
  getCropForImage,
  handleConversation,
  sendCroppedImage,
} from "./aduchatutils";
import chatBtnDisabled from "../assets/icons/adu-chat-disabled-send.svg";
import chatBtn from "../assets/icons/adu-chat-send.svg";

import {
  APIProvider,
  Map,
  AdvancedMarker,
  useAdvancedMarkerRef,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import { LengendComp } from "./adumessagecomps";
// import { GOOGLE_MAPS_API_KEY } from "./config"; // Store your API key securely
import "./aduchatbot.css";
import "../App.css";
import useScreenWidth from "../utils/useScreenWidth";
import DOMPurify from "dompurify";

export const ADUChatInput = ({
  typing,
  setTyping,
  sessionId,
  messages = [],
  setMessages,
  setSelectedLocation,
  setViewport,
  mapCompInput,
  setMapCompInput,
  selectedImage,
  imageForCrop,
  setImageForCrop,
  completedCrop,
  area,
  aduImages,
  setAduImages,
  bedrooms,
  bathrooms,
  addressFromUrl,
}) => {
  const [userInput, setUserInput] = useState("");
  const [mapInput, setMapInput] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);
  const [addressFromURL, setAddressFromURL] = useState(addressFromUrl);
  let lastMessage = messages[messages?.length - 1];
  const isDisabled = typing
    ? typing
    : lastMessage?.showBedBathSelection
    ? !(bedrooms && bathrooms)
    : lastMessage?.showCropImage
    ? completedCrop?.height === 0 || completedCrop?.width === 0
    : lastMessage?.showImage
    ? !selectedImage
    : lastMessage?.showAddress
    ? !mapCompInput
    : !userInput;

  const handleInput = (e) => {
    setUserInput(e.target.value);
    e.target.style.height = "20px";
    const totalHeight = e.target.scrollHeight;
    e.target.style.height = `${totalHeight}px`;
  };

  const handleCoverse = (value) => {
    handleConversation({
      setTyping,
      value,
      sessionId,
      setMessages,
      setAduImages,
      setImageForCrop,
      bedrooms,
      bathrooms,
    });

    setUserInput("");
    setMapInput("");
    setMapCompInput("");
  };

  const handleAfterBedBathSelect = ({ bed, bath }) => {
    setMessages((prevMessages) => {
      const lastMessage = prevMessages[prevMessages.length - 1];

      if (lastMessage?.showBedBathSelection) {
        const updatedMessages = [...prevMessages];
        updatedMessages[updatedMessages.length - 1] = {
          ...defaultMessage,
          content: `${bed}|${bath}`,
          showBedBathSelection: false,
          isBedBath: true,
          sender: "bot",
        };

        updatedMessages.push({
          ...defaultMessage,
          showCropImage: true,
        });

        return updatedMessages;
      }
    });
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default newline behavior
      // handleSend(userInput);
      handleCoverse(userInput);
    }
  };

  const handleSearchAddress = async (value) => {
    setMapInput(value);
    setLoading(true);

    // Abort the previous request if it exists
    if (abortController) {
      abortController.abort();
    }

    // Create a new AbortController for the current request
    const controller = new AbortController();
    setAbortController(controller);

    if (value) {
      try {
        const response = await clientGet(
          `https://nominatim.openstreetmap.org/search?format=json&q=${value}`,
          true,
          controller
        );
        const data = await response.json();
        const formattedOptions = data.map((item) => ({
          value: item.display_name,
          latitude: item.lat,
          longitude: item.lon,
        }));
        setOptions(formattedOptions);
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Error fetching address from API:", error);
        }
      } finally {
        setLoading(false);
      }
    } else {
      setOptions([]);
      setLoading(false);
    }
  };

  const handleSelectAddress = (value, option) => {
    if (!option) return;

    const { latitude, longitude } = option;

    setSelectedLocation({
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
    });
    setViewport((prevViewport) => ({
      ...prevViewport,
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
      zoom: 14,
    }));

    setMapInput(value);
    setMapCompInput(value);
    setOptions([]);
  };

  const handleSendImage = async () => {
    if (selectedImage) {
      setMessages((prevMessages) => {
        const newMessages = [...prevMessages];
        newMessages[newMessages.length - 1] = {
          ...defaultMessage,
          showImgSlctnMsg: true,
          selectedImage: { id: selectedImage?.id },
          aduImages: aduImages,
        };
        newMessages.push({
          ...defaultMessage,
          content: selectedImage.url,
          sender: "user",
          isImage: true,
        });
        return newMessages;
      });

      // await handleConversation({
      //   setTyping,
      //   value: selectedImage.id,
      //   sessionId,
      //   setMessages,
      //   setAduImages,
      //   setImageForCrop,
      //   bedrooms,
      //   bathrooms,
      // });
      sendCroppedImage({
        sessionId,
        setTyping,
        setMessages,
        completedCrop,
        imageForCrop,
        area,
        version: selectedImage?.id,
      });
      localStorage.setItem("selectedAduImage", selectedImage?.id);

      // setMessages((prevMessages) => {
      //   const newMessage = {
      //     ...defaultMessage,
      //     showCropImage: true,
      //   };
      //   return [...prevMessages, newMessage];
      // });
    }
  };

  const handleGenerateFloorPlan = async () => {
    // await generateFloorPlan({
    //   sessionId,
    //   bedrooms,
    //   bathrooms,
    //   setAduImages,
    // });

    // setMessages((prev) => {
    //   const tempMessages = [
    //     ...prev,
    //     {
    //       ...defaultMessage,
    //       showImage: true,
    //     },
    //   ];
    //   return tempMessages;
    // });
    setTyping(true);

    const floorresp = await generateFloorPlan({
      sessionId,
      bedrooms,
      bathrooms,
      setAduImages,
    });

    if (floorresp) {
      setMessages((prev) => {
        const tempMessages = [
          ...prev,
          {
            ...defaultMessage,
            showImage: true,
          },
        ];
        return tempMessages;
      });
    }
    setTyping(false);
  };

  const handleResetStates = () => {
    setUserInput("");
    setMapInput("");
    setMapCompInput("");
    setOptions([]);
    setViewport({
      latitude: 34.052235,
      longitude: -118.243683,
      zoom: 10,
    });
    setSelectedLocation(null);
  };

  const width = useScreenWidth();
  if (lastMessage?.showAddress) {
    return <></>;
  } else {
    return (
      <div
        className="aduchat-input"
        style={{
          position: "absolute",
          bottom: 10,
          width: "100%",
          padding: width > 768 ? "0 1.2rem" : "0 0.5rem",
          display: "flex",
          alignItems: "end",
          gap: width > 768 ? 10 : 5,
          zIndex: 10000,
        }}
      >
        <div
          className="textarea-wrapper custom-element-gradient"
          style={{
            display: "flex",
            alignItems: "flex-end",
            backgroundColor: "white",
            borderRadius: "12px",
            minHeight: "3rem",
            maxHeight: "300px",
            overflowY: "auto",
            width: "100%",
            boxShadow: "0px 0px 25px 0px #C1C1C140",
          }}
        >
          <textarea
            placeholder="Type your text here ...."
            value={userInput}
            onChange={handleInput}
            onKeyDown={handleEnterKeyPress}
            className="autosize-textarea custom-scrollbar"
            style={{
              border: "none",
              overflowY: "auto",
              outline: "none",
              backgroundColor: "white",
              flexGrow: 1,
            }}
            disabled={lastMessage?.showAddress ? true : false}
          />
        </div>
        <button
          className={`aduchat-send-btn 
          ${!isDisabled ? "hovered" : ""}`}
          style={{
            background: isDisabled
              ? "#E5E1D4"
              : "linear-gradient(80.23deg, #7C73BC 13.26%, #B1A7F6 96.76%)",
            borderRadius: "12px",
            cursor: isDisabled ? "auto" : "pointer",
            border: isDisabled ? "1px solid #DBDBDB" : "1px solid #B1A7F6",
            height: width > 767 ? 63 : 53,
            width: width > 767 ? 65 : 55,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          disabled={isDisabled}
          onClick={async () => {
            if (lastMessage?.showAddress) {
              localStorage.setItem("aduChatAddress", mapCompInput || mapInput);
              handleCoverse(mapCompInput || mapInput);
            } else if (lastMessage?.showImage) {
              handleSendImage();
            } else if (lastMessage?.showCropImage) {
              handleGenerateFloorPlan();
            } else if (lastMessage?.showBedBathSelection) {
              handleAfterBedBathSelect({
                bed: bedrooms,
                bath: bathrooms,
              });
            } else {
              handleCoverse(userInput);
            }
          }}
        >
          <img
            style={{
              width: 22,
              height: 22,
              marginTop: "-5px",
              marginRight: "-4px",
              transform: "rotate(320deg)",
            }}
            src={isDisabled ? chatBtnDisabled : chatBtn}
            alt="send"
          />
        </button>
      </div>
    );
  }
};

export const ADUChatMap = ({
  selectedLocation,
  setSelectedLocation,
  viewport,
  setViewport,
  mapCompInput,
  setMapCompInput,
  setTyping,
  sessionId,
  setMessages,
  setAduImages,
  setImageForCrop,
  bedrooms,
  bathrooms,
  addressFromUrl,
}) => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [autocomplete, setAutocomplete] = useState(null);
  const [markerRef, marker] = useAdvancedMarkerRef();
  const inputRef = useRef(null);
  const [selectedPlace, setSelectedPlace] = useState(null);

  const onMapClick = (e) => {
    // console.log("e", e);

    const latLng = {
      lat: e.detail.latLng.lat,
      lng: e.detail.latLng.lng,
    };

    setSelectedLocation({
      latitude: latLng.lat,
      longitude: latLng.lng,
    });

    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          const formattedAddress = results[0].formatted_address;
          setMapCompInput(formattedAddress);
          // console.log("Address:", formattedAddress);
        } else {
          console.log("No results found");
        }
      } else {
        console.error("Geocoder failed due to: " + status);
      }
    });
  };

  const handleCoverse = (value) => {
    handleConversation({
      setTyping,
      value,
      sessionId,
      setMessages,
      setAduImages,
      setImageForCrop,
      bedrooms,
      bathrooms,
    });

    setMapCompInput("");
  };

  const width = useScreenWidth();
  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          position: "relative",
          gap: 10,
        }}
      >
        {step === 1 ? (
          <div
            className="aduchat-input"
            style={{
              position: "absolute",
              bottom: "50%",
              width: "95%",
              padding: width > 768 ? "0 1.2rem" : "0 0.5rem",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: width > 768 ? 30 : 20,
            }}
          >
            <div
              style={{
                color: "#504D3F",
                fontSize: width > 768 ? "32px" : "18px",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              Please tell me your full address to start
            </div>

            <div
              className="aduchat-input"
              style={{
                width: "100%",
                padding: width > 768 ? "0 1.2rem" : "0 0.5rem",
                display: "flex",
                alignItems: "center",
                gap: width > 768 ? 10 : 5,
              }}
            >
              <div
                className="autocomplete-control textarea-wrapper custom-element-gradient"
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  backgroundColor: "white",
                  borderRadius: "12px",
                  height: width > 767 ? 63 : 53,
                  minHeight: "3rem",
                  maxHeight: "300px",
                  overflowY: "auto",
                  width: "100%",
                  boxShadow: "0px 0px 25px 0px #C1C1C140",
                }}
              >
                <PlaceAutocomplete
                  onPlaceSelect={setMapCompInput}
                  setSelectedLocation={setSelectedLocation}
                  setViewport={setViewport}
                  mapCompInput={mapCompInput}
                  addressFromUrl={addressFromUrl}
                />
              </div>

              <button
                className={`aduchat-send-btn 
             ${!mapCompInput ? "" : "hovered"}`}
                style={{
                  background: !mapCompInput
                    ? "#E5E1D4"
                    : "linear-gradient(80.23deg, #7C73BC 13.26%, #B1A7F6 96.76%)",
                  borderRadius: "12px",
                  cursor: !mapCompInput ? "auto" : "pointer",
                  border: !mapCompInput
                    ? "1px solid #DBDBDB"
                    : "1px solid #B1A7F6",
                  height: width > 767 ? 63 : 53,
                  width: width > 767 ? 65 : 55,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                disabled={!mapCompInput}
                onClick={async () => {
                  setStep(2);
                }}
              >
                <img
                  style={{
                    width: 22,
                    height: 22,
                    marginTop: "-5px",
                    marginRight: "-4px",
                    transform: "rotate(320deg)",
                  }}
                  src={!mapCompInput ? chatBtnDisabled : chatBtn}
                  alt="send"
                />
              </button>
            </div>
          </div>
        ) : (
          <div
            className="adu-fadein-animation custom-map-style"
            style={{
              position: "relative",
              height: "100%",
              width: "95%",
              margin: "0 auto",
              maxWidth: 926,
              aspectRatio: "16 / 9",
              overflow: "hidden",
              flexGrow: 1,
              borderRadius: 20,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              border: "1px solid #F5E5DB",
              boxShadow: "0px 0px 25px 0px #C1C1C140",
            }}
          >
            <Map
              defaultCenter={{
                lat: viewport.latitude,
                lng: viewport.longitude,
              }}
              defaultZoom={viewport.zoom}
              gestureHandling="greedy"
              disableDefaultUI
              style={{ width: "100%", height: "100%" }}
              onClick={onMapClick}
              mapId={process.env.REACT_APP_GOOGLE_API_KEY}
              viewState={viewport}
            >
              {selectedLocation?.latitude && selectedLocation?.longitude && (
                <AdvancedMarker
                  ref={markerRef}
                  position={{
                    lat: selectedLocation.latitude,
                    lng: selectedLocation.longitude,
                  }}
                />
              )}
            </Map>
          </div>
        )}
        {step === 2 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              margin: "0 auto",
              marginTop: "10px",
              width: "95%",
            }}
          >
            <div
              style={{
                color: "#504D3F",
                fontSize: width > 768 ? "18px" : "14px",
                fontWeight: "400",
              }}
            >
              Showing <strong>{mapCompInput}</strong> on the map.
              <br />
              Can you confirm this address?
            </div>
            <div
              className={"adu-fadein-animation"}
              style={{
                display: "flex",
                gap: 10,
                marginTop: "10px",
              }}
            >
              {["Yes, that’s correct", "Go back to edit"]?.map(
                (value, index) => {
                  return (
                    <AntdButton
                      key={index}
                      className="btn-gradient-border"
                      style={{
                        borderRadius: 20,
                        padding: "4px 12px",
                        height: "unset",
                      }}
                      onClick={() => {
                        if (value === "Yes, that’s correct") {
                          localStorage.setItem("aduChatAddress", mapCompInput);
                          handleCoverse(mapCompInput);
                        } else {
                          setStep(1);
                        }
                      }}
                    >
                      {value}
                    </AntdButton>
                  );
                }
              )}
            </div>
          </div>
        )}
      </div>
    </APIProvider>
  );
};
const PlaceAutocomplete = ({
  onPlaceSelect,
  setSelectedLocation,
  setViewport,
  mapCompInput,
  addressFromUrl,
}) => {
  const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
  const inputRef = useRef(null);
  const places = useMapsLibrary("places");

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ["geometry", "name", "formatted_address"],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener("place_changed", () => {
      // setMapInput(value);

      let thisPlace = placeAutocomplete.getPlace();
      console.log(thisPlace);
      // onPlaceSelect(thisPlace);
      if (thisPlace.formatted_address && thisPlace.geometry.location) {
        setSelectedLocation({
          latitude: thisPlace.geometry.location.lat(),
          longitude: thisPlace.geometry.location.lng(),
        });
        setViewport((prev) => ({
          ...prev,
          latitude: thisPlace.geometry.location.lat(),
          longitude: thisPlace.geometry.location.lng(),
          zoom: 14,
        }));
        console.log("ADDRRRRR", addressFromUrl);
        onPlaceSelect(thisPlace.formatted_address);
      } else {
        setSelectedLocation(null);
        setViewport({
          latitude: 34.052235,
          longitude: -118.243683,
          zoom: 10,
        });
      }
    });
  }, [onPlaceSelect, placeAutocomplete]);

  const width = useScreenWidth();
  return (
    <div className="autocomplete-container">
      <input
        ref={inputRef}
        value={mapCompInput}
        onChange={(e) => onPlaceSelect(e.target.value)}
        style={{
          width: "100%",
          height: width > 767 ? 63 : 53,
          padding: "15px",
          borderRadius: "12px",
          border: 0,
        }}
        placeholder="Please enter your address here"
      />
    </div>
  );
};

export const ADUImageSelection = ({
  aduImages,
  selectedImage,
  setSelectedImage,
  sessionId,
  setAduImages,
  bedrooms,
  bathrooms,
}) => {
  const [loading, setLoading] = useState(false);

  const lightboxphotos = aduImages?.map((values) => {
    return {
      src: values.url,
      type: null,
    };
  });
  const [state, setState] = useState({
    lightBoxOpen: false,
    currentIndex: 0,
    count: lightboxphotos.length,
    photos: lightboxphotos,
    lightBoxPhotos: lightboxphotos,
    isLoading: false,
    hasMore: true,
  });

  const onClickPhoto = (index) => {
    setState((prev) => ({
      ...prev,
      lightBoxOpen: true,
      currentIndex: index,
    }));
  };

  const onClickPrevious = () => {
    setState((prev) => ({
      ...prev,
      currentIndex: prev.currentIndex - 1,
    }));
  };

  const onClickNext = () => {
    setState((prev) => ({
      ...prev,
      currentIndex: prev.currentIndex + 1,
    }));
  };

  const handleImageClick = (image) => {
    setSelectedImage(selectedImage === image ? null : image);
  };

  const handleDownload = (image) => {
    const link = document.createElement("a");
    link.href = image.url; // The image URL
    link.download = `ADU-${image.id}.jpg`; // Provide a default filename
    link.click();
  };
  const width = useScreenWidth();
  return (
    <div className="adu-fadein-animation">
      <div
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <div
            className={`${"adu-fadein-animation"}
          
          `}
            style={{
              maxWidth: "100%",
              minHeight: width > 768 ? "70px" : "50px",
              padding: width > 768 ? 20 : 10,
              color: "#504D3F",
              backgroundColor: "#FFFFFF80",
              border: "1px solid #DBDBDB",
              borderRadius: "12px 12px 12px 0px",
              boxShadow: "0px 0px 25px 0px #C1C1C140",
              fontWeight: 400,
              fontSize: width > 768 ? 16 : 14,
              lineHeight: "25px",
              marginBottom: "1rem",

              // color: message?.sender === "user" ? "black" : "white",
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  "We've generated ADU interior layout options for you, please select your favorite one!"
                ),
              }}
              className="bg-white w-fit"
            />
          </div>
        </div>
      </div>

      <div
        style={{
          fontSize: width > 768 ? "18px" : "14px",
          display: "flex",
          justifyContent: "space-between",
          gap: 10,
          width: "100%",
          maxWidth: "max-content",
        }}
      >
        {/* <div style={{ flexGrow: 1 }}>Please select one image</div> */}
        <div
          style={{
            cursor: loading ? "unset" : "pointer",
            color: loading ? "lightgray" : "#947AEC",
          }}
          onClick={async () => {
            if (!loading) {
              setLoading(true);
              await generateFloorPlan({
                sessionId,
                bedrooms,
                bathrooms,
                setAduImages,
              });
              setLoading(false);
            }
          }}
        >
          <Tooltip title="Regenerate Images" style={{ right: 0 }}>
            <SyncOutlined
              style={{ color: loading ? "lightgray" : "#947AEC" }}
            />{" "}
            Regenerate
          </Tooltip>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
          alignItems: "center",
          position: "relative",
          width: "100%",
          // maxWidth: "450px",
          marginTop: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 10,
            // position: "relative",
            width: "100%",
            // maxWidth: "450px",
          }}
        >
          {/* Image Grid Section */}
          <div
            style={{
              display: "grid",
              gap: 5,
              gridTemplateColumns: "repeat(2, 1fr)",
              width: "100%",
              position: "relative",
            }}
          >
            {aduImages?.map((image, index) => (
              <div
                key={image.id}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                }}
              >
                <div
                  key={image.id}
                  style={{
                    position: "relative",
                    background: "#FFFFFF80",
                    border:
                      image === selectedImage
                        ? "2px solid #826DFB"
                        : "2px solid lightgray",
                    borderRadius: 8,
                    padding: 2,
                    opacity: image === selectedImage ? 0.7 : 1,
                    cursor: loading ? "unset" : "pointer",
                  }}
                >
                  <img
                    disabled={loading}
                    onClick={(e) => {
                      if (!loading) {
                        handleImageClick(image);
                        e.stopPropagation();
                      }
                    }}
                    src={image.url}
                    alt={`ADU ${image.id}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: 8,
                    }}
                  />
                  <div
                    onClick={() => onClickPhoto(index)}
                    className="eye-btn"
                    style={{
                      position: "absolute",
                      top: 5,
                      right: 30,
                      backgroundColor: "transparent",
                      borderRadius: "50%",
                      height: 18,
                      width: 18,
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 2,
                    }}
                  >
                    <EyeOutlined />
                  </div>
                  <div
                    onClick={() => handleDownload(image)}
                    className="eye-btn"
                    style={{
                      position: "absolute",
                      top: 5,
                      right: 5,
                      backgroundColor: "transparent",
                      borderRadius: "50%",
                      height: 18,
                      width: 18,
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 2,
                    }}
                  >
                    <DownloadOutlined />
                  </div>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    zIndex: 2,
                  }}
                >
                  {image.text || ""}
                </div>
              </div>
            ))}
            {/* Loading Overlay */}
            {loading && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  zIndex: 3,
                  backgroundColor: "rgba(255,255,255,0.6)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 10,
                }}
              >
                <div className="aduchat-image-selection-loader" />
              </div>
            )}
          </div>
          {/* Legend Section */}
          <LengendComp />
        </div>

        {/* Lightbox */}
        {state.lightBoxOpen && (
          <Lightbox
            images={state.lightBoxPhotos}
            isOpen={state.lightBoxOpen}
            currentImage={state.currentIndex}
            onClickPrev={onClickPrevious}
            onClickNext={onClickNext}
            onClose={() => setState({ ...state, lightBoxOpen: false })}
            className="adu-lightbox"
            styles={{
              image: { border: "5px solid white" },
            }}
          />
        )}
      </div>
    </div>
  );

  // return (
  //   <div className="adu-fadein-animation">
  //     <div
  //       style={{
  //         fontSize: "18px",
  //         fontWeight: "bold",
  //         display: "flex",
  //         justifyContent: "space-between",
  //         width: "100%",
  //         maxWidth: "350px",
  //       }}
  //     >
  //       <div
  //         style={{
  //           flexGrow: 1,
  //         }}
  //       >
  //         Please select one image
  //       </div>
  //       <div
  //         style={{ cursor: loading ? "unset" : "pointer" }}
  //         onClick={async () => {
  //           if (!loading) {
  //             setLoading(true);
  //             await generateFloorPlan({
  //               sessionId,
  //               bedrooms,
  //               bathrooms,
  //               setAduImages,
  //             });
  //             setLoading(false);
  //           }
  //         }}
  //         disabled={loading}
  //       >
  //         <Tooltip title="Regenerate">
  //           <ReloadOutlined
  //             style={{ color: loading ? "lightgray" : "rgb(245, 167, 36)" }}
  //           />
  //         </Tooltip>
  //       </div>
  //     </div>
  //     <div
  //       style={{
  //         position: "relative",
  //         display: "grid",
  //         gap: 5,
  //         gridTemplateColumns: "repeat(2, 1fr)",
  //         gridTemplateRows: "repeat(2, auto)",
  //         width: "100%",
  //         maxWidth: "350px",
  //         maxHeight: "350px",
  //       }}
  //     >
  //       {aduImages?.map((image, index) => (
  //         <div
  //           key={image.id}
  //           style={{
  //             position: "relative",
  //             border:
  //               image === selectedImage
  //                 ? "2px solid rgb(245, 167, 36)"
  //                 : "2px solid lightgray",
  //             borderRadius: 8,
  //             padding: 2,
  //             opacity: image === selectedImage ? 0.7 : 1,
  //             cursor: loading ? "unset" : "pointer",
  //           }}
  //         >
  //           <img
  //             disabled={loading}
  //             onClick={(e) => {
  //               if (!loading) {
  //                 handleImageClick(image);
  //                 e.stopPropagation();
  //               }
  //             }}
  //             src={image.url}
  //             alt={`ADU ${image.id}`}
  //             style={{
  //               width: "100%",
  //               height: "100%",
  //               objectFit: "cover",
  //               borderRadius: 8,
  //             }}
  //           />
  //           <div
  //             onClick={() => onClickPhoto(index)}
  //             style={{
  //               position: "absolute",
  //               top: 5,
  //               right: 5,
  //               backgroundColor: "rgba(255, 255, 255, 0.7)",
  //               borderRadius: "50%",
  //               height: 18,
  //               width: 18,
  //               cursor: "pointer",
  //               display: "flex",
  //               justifyContent: "center",
  //               alignItems: "center",
  //               zIndex: 2,
  //             }}
  //           >
  //             <EyeOutlined />
  //           </div>
  //           <div
  //             style={{
  //               position: "absolute",
  //               bottom: 5,
  //               right: 5,
  //               height: 18,
  //               width: "max-content",
  //               zIndex: 2,
  //               fontWeight: "bold",
  //             }}
  //           >
  //             {image.text || ""}
  //           </div>
  //         </div>
  //       ))}
  //       {/* Legend Section */}
  //       <div
  //         style={{
  //           display: "flex",
  //           flexDirection: "column",
  //           gap: 8,
  //           alignItems: "flex-start",
  //           paddingLeft: 10,
  //           maxWidth: "100px",
  //         }}
  //       >
  //         <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
  //           <div
  //             style={{
  //               width: 15,
  //               height: 15,
  //               backgroundColor: "red",
  //               borderRadius: 2,
  //             }}
  //           />
  //           <span>Living Room</span>
  //         </div>
  //         <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
  //           <div
  //             style={{
  //               width: 15,
  //               height: 15,
  //               backgroundColor: "gray",
  //               borderRadius: 2,
  //             }}
  //           />
  //           <span>Bathroom</span>
  //         </div>
  //         <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
  //           <div
  //             style={{
  //               width: 15,
  //               height: 15,
  //               backgroundColor: "yellow",
  //               borderRadius: 2,
  //             }}
  //           />
  //           <span>Bedroom</span>
  //         </div>
  //       </div>
  //       {state.lightBoxOpen && (
  //         <Lightbox
  //           images={state.lightBoxPhotos}
  //           isOpen={state.lightBoxOpen}
  //           currentImage={state.currentIndex}
  //           onClickPrev={onClickPrevious}
  //           onClickNext={onClickNext}
  //           onClose={(e) => setState({ ...state, lightBoxOpen: false })}
  //         />
  //       )}

  //       {loading && (
  //         <div
  //           style={{
  //             position: "absolute",
  //             height: "100%",
  //             width: "100%",
  //             zIndex: 3,
  //             backgroundColor: "rgba(255,255,255,0.6)",
  //             display: "flex",
  //             justifyContent: "center",
  //             alignItems: "center",
  //           }}
  //         >
  //           <div className="aduchat-image-selection-loader" />
  //         </div>
  //       )}
  //     </div>
  //   </div>
  // );
};

export const ADUImageCrop = ({
  sessionId,
  imageUrl,
  setCompletedCrop,
  area,
  setArea,
}) => {
  const [crop, setCrop] = useState({ aspect: 1 });

  const [initialDimensions, setInitialDimensions] = useState({
    maxArea: null,
    area: null,
    width: null,
    height: null,
  });
  const imageRef = useRef(null);

  const handleCropChange = (newCrop) => {
    if (initialDimensions.maxArea) {
      const newArea = calculateArea(newCrop);
      if (newArea <= initialDimensions.maxArea) {
        setCrop(newCrop);
        setArea(newArea);
      } else {
        const scaledCrop = scaleCropToMaxArea(
          newCrop,
          initialDimensions.maxArea
        );
        setCrop(scaledCrop);
        setArea(initialDimensions.maxArea);
      }
    } else {
      setCrop(newCrop);
    }
  };

  const handleCropComplete = (newCrop) => {
    if (imageRef.current) {
      const { naturalHeight, naturalWidth } = imageRef.current;
      const { width, height } = imageRef.current.getBoundingClientRect();
      const newArea = calculateArea(newCrop);

      if (newArea <= initialDimensions.maxArea) {
        setCompletedCrop({
          ...newCrop,
          originalHeight: naturalHeight,
          originalWidth: naturalWidth,
          displayedWidth: width,
          displayedHeight: height,
        });
        setArea(newArea);
      } else {
        const scaledCrop = scaleCropToMaxArea(
          newCrop,
          initialDimensions.maxArea
        );
        setCompletedCrop({
          ...scaledCrop,
          originalHeight: naturalHeight,
          originalWidth: naturalWidth,
          displayedWidth: width,
          displayedHeight: height,
        });
        setArea(initialDimensions.maxArea);
      }
    }
  };

  const onLoad = async (img) => {
    imageRef.current = img;
    const { naturalHeight, naturalWidth } = img;

    const { width, height } = img.getBoundingClientRect();
    const boundingBox = await getCropForImage({ sessionId, width, height });
    if (boundingBox) {
      setArea(boundingBox.area);
      setInitialDimensions({
        maxArea: boundingBox.maxArea,
        area: boundingBox.area,
        width: boundingBox.width,
        height: boundingBox.height,
      });
      setCrop({
        x: boundingBox.x,
        y: boundingBox.y,
        width: boundingBox.width,
        height: boundingBox.height,
        aspect: 1,
      });
      setCompletedCrop({
        originalHeight: naturalHeight,
        originalWidth: naturalWidth,
        x: boundingBox.x,
        y: boundingBox.y,
        width: boundingBox.width,
        height: boundingBox.height,
        aspect: 1,
      });
    }
  };

  const calculateArea = ({ width, height }) => {
    const newArea =
      (parseFloat(initialDimensions?.area) *
        parseFloat(width) *
        parseFloat(height)) /
      (parseFloat(initialDimensions?.width) *
        parseFloat(initialDimensions?.height));

    return newArea;
  };

  const scaleCropToMaxArea = (crop, maxArea) => {
    const currentArea = calculateArea(crop);
    const scaleFactor = Math.sqrt(maxArea / currentArea);

    return {
      ...crop,
      width: crop.width * scaleFactor,
      height: crop.height * scaleFactor,
    };
  };

  const width = useScreenWidth();
  // return (
  //   <div className="adu-fadein-animation">
  //     <div
  //       style={{ fontSize: width > 768 ? "18px" : "14px", fontWeight: "bold" }}
  //     >
  //       Please select the desired area
  //     </div>
  //     <iframe
  //       src="http://localhost:3002" // Replace with the desired URL
  //       title="Area Selector"
  //       style={{
  //         width: "100%",
  //         height: "250px",
  //         width: "420px",
  //         // maxWidth: "40vh",
  //         // maxHeight: "40vh",
  //         objectFit: "cover",
  //         borderRadius: 9,
  //         border: "none", // Optional: removes iframe border
  //       }}
  //     ></iframe>

  //     {area && !isNaN(area) && (
  //       <div>
  //         <strong>Area:</strong> {area.toFixed(2)} sqft
  //       </div>
  //     )}
  //   </div>
  // );

  // return (
  //   <div className="adu-fadein-animation">
  //     <div
  //       style={{ fontSize: width > 768 ? "18px" : "14px", fontWeight: "bold" }}
  //     >
  //       Please select the desired area
  //     </div>
  //     <NewCrop
  //       // src="http://localhost:3002" // Replace with the desired URL
  //       // title="Area Selector"
  //       style={{
  //         width: "100%",
  //         height: "250px",
  //         width: "420px",
  //         // maxWidth: "40vh",
  //         // maxHeight: "40vh",
  //         objectFit: "cover",
  //         borderRadius: 9,
  //         border: "none", // Optional: removes iframe border
  //       }}
  //     />

  //     {area && !isNaN(area) && (
  //       <div>
  //         <strong>Area:</strong> {area.toFixed(2)} sqft
  //       </div>
  //     )}
  //   </div>
  // );
  return (
    <div
      className="adu-fadein-animation"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width,
      }}
    >
      <div
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <div
            className={`${"adu-fadein-animation"}
          
          `}
            style={{
              maxWidth: "100%",
              minHeight: width > 768 ? "70px" : "50px",
              padding: width > 768 ? 20 : 10,
              color: "#504D3F",
              backgroundColor: "#FFFFFF80",
              border: "1px solid #DBDBDB",
              borderRadius: "12px 12px 12px 0px",
              boxShadow: "0px 0px 25px 0px #C1C1C140",
              fontWeight: 400,
              fontSize: width > 768 ? 16 : 14,
              lineHeight: "25px",
              marginBottom: "1rem",

              // color: message?.sender === "user" ? "black" : "white",
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  "Great! Now you can use the tools below to select the exact spot on the map where you'd like to build the ADU. The area you've selected will display in real-time."
                ),
              }}
              className="bg-white w-fit"
            />
          </div>
        </div>
      </div>
      <div className="image-crop-container">
        <div
          style={{
            width: "100%",
            height: "100%",
            // height:"250px",
            // width: "420px",
            // maxWidth: "800px",
            // maxHeight: "200px",
            overflow: "hidden", // Ensures child content stays within bounds
          }}
        >
          <NewCrop />
        </div>
        <div
          style={{
            background: "white",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: width > 768 ? "1rem" : 10,
            padding: "20px",
            fontWeight: 400,
            fontSize: width > 768 ? 16 : 14,
            lineHeight: "25px",
          }}
        >
          <div style={{ width: width > 768 ? "auto" : "100%" }}>
            <strong>ADU Placement</strong>
          </div>
          <div
            style={{
              background: "#848484",
              height: width > 768 ? 30 : 25,
              width: width > 768 ? 30 : 25,
            }}
          ></div>
          <div>Excluded area</div>
          <div
            style={{
              background: "#E4E4E4",
              height: width > 768 ? 30 : 25,
              width: width > 768 ? 30 : 25,
            }}
          ></div>
          <div>Available area</div>
        </div>
      </div>
      {area && !isNaN(area) && (
        <div style={{ marginTop: "10px" }}>
          <strong>Area:</strong> {area.toFixed(2)} sqft
        </div>
      )}
    </div>
  );

  // return (
  //   <div className="adu-fadein-animation">
  //     <div
  //       style={{ fontSize: width > 768 ? "18px" : "14px", fontWeight: "bold" }}
  //     >
  //       Please select the desired area
  //     </div>
  //     <ReactCrop
  //       style={{
  //         width: "100%",
  //         height: "max-content",
  //         maxWidth: "350px",
  //         maxHeight: "350px",
  //         objectFit: "cover",
  //         borderRadius: 9,
  //       }}
  //       src={imageUrl}
  //       crop={crop}
  //       onChange={handleCropChange}
  //       onComplete={handleCropComplete}
  //       aspect={1}
  //       onImageLoaded={onLoad}
  //       imageStyle={{
  //         width: "100%",
  //         height: "100%",
  //         maxWidth: "350px",
  //         maxHeight: "350px",
  //         objectFit: "cover",
  //         borderRadius: 9,
  //       }}
  //     />

  //     {area && !isNaN(area) && (
  //       <div>
  //         <strong>Area:</strong> {area.toFixed(2)} sqft
  //       </div>
  //     )}
  //   </div>
  // );
};

export const ADUBedBathSelection = ({
  bedrooms,
  setBedrooms,
  bathrooms,
  setBathrooms,
  bedroomOptions,
  bathroomOptions,
}) => {
  const handleBedroomChange = (value) => setBedrooms(value);
  const handleBathroomChange = (value) => setBathrooms(value);

  const onClear = () => {
    setBedrooms(null);
    setBathrooms(null);
  };

  const width = useScreenWidth();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: width > 768 ? 20 : 20,
        padding: width > 768 ? "2rem" : "1.5rem",
        maxWidth: "max-content",
        background: "#FFFFFF80",
        borderRadius: "12px",
        border: "1px solid #CECECE",
        boxShadow: "0px 0px 25px 0px #C1C1C140",
      }}
    >
      {/* <div style={{ fontSize: "18px", fontWeight: "bold" }}>
        Please select number of bedrooms
      </div> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: width > 768 ? 20 : 20,
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <div
              style={{ fontWeight: "bold", fontSize: width > 768 ? 18 : 14 }}
            >
              Bedrooms
            </div>
            <div
              onClick={onClear}
              style={{
                fontSize: width > 768 ? 16 : 14,
                color: "#606060",
                cursor: "pointer",
              }}
            >
              Clear
            </div>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {(bedroomOptions || []).map((option, index) => (
              <div
                key={option}
                onClick={() => handleBedroomChange(option)}
                className="custom-element-gradient"
                style={{
                  padding: width > 768 ? "10px 20px" : "8px 15px",
                  border: ` ${
                    bedrooms === option
                      ? "1.5px solid #826DFB"
                      : "1px solid #ccc"
                  }`,
                  backgroundColor: "#fff",
                  color: "#504D3F",
                  cursor: "pointer",
                  fontWeight: 400,
                  fontSize: width > 768 ? 18 : 14,
                }}
              >
                {option}
              </div>
            ))}
          </div>
        </div>

        <div>
          <label
            style={{
              fontWeight: "bold",
              fontSize: width > 768 ? 18 : 14,
              marginBottom: 10,
              display: "block",
            }}
          >
            Bathrooms
          </label>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {(bathroomOptions || []).map((option, index) => (
              <div
                key={option}
                onClick={() => handleBathroomChange(option)}
                className="custom-element-gradient"
                style={{
                  padding: width > 768 ? "10px 20px" : "8px 15px",
                  border: ` ${
                    bathrooms === option
                      ? "1.5px solid #826DFB"
                      : "1px solid #ccc"
                  }`,
                  backgroundColor: "#fff",
                  color: "#504D3F",
                  cursor: "pointer",
                  fontWeight: 400,
                  fontSize: width > 768 ? 18 : 14,
                }}
              >
                {option}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
