// import { useState, useRef, useMemo, useEffect } from "react";
// import {
//   MapContainer,
//   TileLayer,
//   Polyline,
//   useMapEvents,
//   Marker,
//   Tooltip,
//   FeatureGroup,
//   Polygon,
// } from "react-leaflet";
// import { EditControl } from "react-leaflet-draw";
// import "leaflet/dist/leaflet.css";
// import "leaflet-draw/dist/leaflet.draw.css";
// import L from "leaflet";
// // import {ReactLeafletDistortableImage} from "@diwotech/leaflet-distortableimage"
// const lat_adjust = 0.0000127979272
// const long_adjust  = 0.00002413988112

// const center1 = [33.668183, 73.074943];
// const center2 = [33.668093, 73.074745];

// function MapCornerLogger() {
//   const map = useMapEvents({
//     moveend: () => {
//       const bounds = map.getBounds();
//       const southwest = bounds.getSouthWest();
//       const northeast = bounds.getNorthEast();

//       console.log("Southwest Corner:", southwest);
//       console.log("Northeast Corner:", northeast);
//     },
//     click: (e) => {
//       const { lat, lng } = e.latlng;
//       console.log("Clicked Lat, Lng:", lat, lng);
//     },
//   });

//   return null;
// }

// function DraggableMarker({ position, setPosition }) {
//   const markerRef = useRef(null);
//   const eventHandlers = useMemo(
//     () => ({
//       dragend() {
//         const marker = markerRef.current;
//         if (marker != null) {
//           setPosition(marker.getLatLng());
//         }
//       },
//     }),
//     [setPosition]
//   );

//   return (
//     <Marker
//       draggable={true}
//       eventHandlers={eventHandlers}
//       position={position}
//       ref={markerRef}
//     />
//   );
// }

// function NewCrop() {
//   const [marker1Pos, setMarker1Pos] = useState(center1);
//   const [marker2Pos] = useState(center2);
//   const [distance, setDistance] = useState(0);
//   const [midpoint, setMidpoint] = useState(null);
//   const [areaSqFt, setAreaSqFt] = useState(0);
//   const featureGroupRef = useRef(null);
//   const [mapType, setMapType] = useState("roadmap");
//   const [firstSwitch, setFirstSwitch] = useState(false)
//   const tileLayerURL =
//     mapType === "roadmap"
//       ? "https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
//       : "https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}";

//   // const grayAreaCorners = useMemo(() => {
//   //   const bounds = [
//   //     [90, -180],  // Top-left corner of the map (world bounds)
//   //     [90, 180],   // Top-right corner
//   //     [-90, 180],  // Bottom-right corner
//   //     [-90, -180], // Bottom-left corner
//   //   ];

//   //   let hole = [
//   //     [37.32209796296457, -121.9568882882595], // Replace these with the desired coordinates
//   //     [37.32209796296457, -121.95646718144418],
//   //     [37.32189319477203, -121.95645913481715],
//   //     [37.321891061767076, -121.9568856060505],
//   //   ];
//   //   if(mapType != 'roadmap'){
//   //     console.log("ROADMAP")
//   //     hole.forEach((value) =>{
//   //       value = [value[0]+lat_adjust, value[1]-long_adjust]
//   //     })
//   //     setFirstSwitch(true)
//   //   }
//   //   else if(firstSwitch){
//   //     console.log("mAP")
//   //     hole.forEach((value) =>{
//   //       value = [value[0]-lat_adjust, value[1]+long_adjust]
//   //     })
      
//   //   }
//   //   return [bounds, hole];
//   // }, []);
//     const [hole, setHole] = useState([
//       [37.32209796296457, -121.9568882882595],
//       [37.32209796296457, -121.95646718144418],
//       [37.32189319477203, -121.95645913481715],
//       [37.321891061767076, -121.9568856060505],
//       // [37.32235116752604, -121.95582975950721], [37.32225716752604, -121.9562717595072], [37.32228016752604, -121.95628075950721], [37.32228066752604, -121.95628125950721], [37.32230216752604, -121.95629175950721], [37.322303667526036, -121.9562927595072], [37.322326167526036, -121.9563062595072], [37.32234616752604, -121.9563217595072], [37.32234716752604, -121.9563222595072], [37.32236616752604, -121.9563402595072], [37.32236716752604, -121.95634075950721], [37.32238566752604, -121.9563607595072], [37.32243066752604, -121.95630775950721], [37.32245616752604, -121.9562432595072], [37.32249716752604, -121.95613975950721], [37.322506167526036, -121.95611725950721], [37.32253316752604, -121.95604975950721], [37.32248466752604, -121.9558312595072], [37.32246766752604, -121.95583075950721], [37.32235116752604, -121.95582975950721]
//     ]);
    
//     useEffect(() => {
//       const updatedHole = hole.map((value) => {
//         if (mapType !== 'roadmap' && !firstSwitch) {
//           return [value[0] + lat_adjust, value[1] - long_adjust];
//         } else if (mapType === 'roadmap' && firstSwitch) {
//           return [value[0] - lat_adjust, value[1] + long_adjust];
//         }
//         return value;
//       });
    
//       setHole(updatedHole);
//       setFirstSwitch(mapType !== 'roadmap');
//     }, [mapType, firstSwitch]);
    
//     const grayAreaCorners = useMemo(() => {
//       const bounds = [
//         [90, -180],
//         [90, 180],
//         [-90, 180],
//         [-90, -180],
//       ];
//       return [bounds, hole];
//     }, [hole]);
//   useEffect(() => {
//     const calculateDistance = () => {
//       const point1 = L.latLng(marker1Pos);
//       const point2 = L.latLng(marker2Pos);
//       return point1.distanceTo(point2).toFixed(2);
//     };

//     const calculateMidpoint = () => [
//       (marker1Pos[0] + marker2Pos[0]) / 2,
//       (marker1Pos[1] + marker2Pos[1]) / 2,
//     ];

//     setDistance(calculateDistance());
//     setMidpoint(calculateMidpoint());
//     console.log("midpoint ", midpoint);
//   }, [marker1Pos, marker2Pos]);

 
//   const updateDistance = (newPos) => {
//     setMarker1Pos(newPos);
//   };

//   const handleDrawCreated = (e) => {
//     const layer = e.layer;
//     const type = e.layerType;

//     if (type === "polygon" || type === "rectangle") {
//       const latLngs = type === "polygon" ? layer.getLatLngs()[0] : layer.getLatLngs();
//       const areaMeters = L.GeometryUtil.geodesicArea(latLngs);
//       const areaFeet = (areaMeters * 10.7639).toFixed(0);
//       setAreaSqFt(areaFeet);

//       layer.on("editresize", () => {
//         const resizedLatLngs = layer.getLatLngs();
//         const resizedAreaMeters = L.GeometryUtil.geodesicArea(resizedLatLngs);
//         const resizedAreaFeet = (resizedAreaMeters * 10.7639).toFixed(0);
//         setAreaSqFt(resizedAreaFeet);
//       });
//     }
    
//     layer.bindPopup("Shape created!").openPopup();
//   };

//   const handleDrawEdited = (e) => {
//     e.layers.eachLayer((layer) => {
//       if (layer instanceof L.Polygon || layer instanceof L.Rectangle) {
//         const latLngs = layer instanceof L.Polygon ? layer.getLatLngs()[0] : layer.getLatLngs();
//         const areaMeters = L.GeometryUtil.geodesicArea(latLngs);
//         const areaFeet = (areaMeters * 10.7639).toFixed(0);
//         setAreaSqFt(areaFeet);
//       }
//     });
//   };

//   const handleClearAll = () => {
//     const layers = featureGroupRef.current.getLayers();
//     layers.forEach((layer) => featureGroupRef.current.removeLayer(layer));
//     setAreaSqFt(0);
//   };

//   const toggleMapType = () => {
//     setMapType((prevMapType) => (prevMapType === "roadmap" ? "satellite" : "roadmap"));
//   };

//   return (
//     <div style={{ display: "flex"}}>
    

//       <MapContainer
//         style={{
//           height: "100vh",
//           width: "100%",
//         }}
//         center={[37.321999, -121.956612]}
//         zoom={20}
//         maxZoom={30}
//       >
//         <TileLayer
//           attribution="Google Maps"
//           url={tileLayerURL}
//           maxNativeZoom={28}
//           maxZoom={30}
//         />
//         <MapCornerLogger />

//         {/* Gray overlay with hole */}
//         <Polygon positions={grayAreaCorners} pathOptions={{ color: "gray", fillOpacity: 0.5 }} />

//         <DraggableMarker position={marker1Pos} setPosition={updateDistance} />
//         <Marker position={marker2Pos} />

//         <Polyline
//           positions={[marker1Pos, marker2Pos]}
//           pathOptions={{ color: "black", dashArray: "5, 10" }}
//         >
//           {midpoint && distance && (
//             <Tooltip permanent position={midpoint}>
//               Distance: {distance} meters
//             </Tooltip>
//           )}
//         </Polyline>

//         <FeatureGroup ref={featureGroupRef}>
//           <EditControl
//             position="topright"
//             onCreated={handleDrawCreated}
//             onEdited={handleDrawEdited}
//             onDeleted={handleClearAll}
//             draw={{
//               polyline: false,
//               circle: false,
//               marker: false,
//               polygon: {
//                 allowIntersection: false,
//                 shapeOptions: {
//                   color: "#97009c",
//                 },
//               },
//               rectangle: true,
//             }}
//             edit={{
//               remove: true,
//             }}
//           />
//         </FeatureGroup>
//       </MapContainer>
//     {/* Bottom-left controls */}
//     {/* <div
//       style={{
//         position: "absolute",
//         top: "50%", // Relative to parent container
//         left: "50%", // Relative to parent container
//         display: "flex",
//         flexDirection: "column", // Stack controls vertically
//         alignItems: "flex-start", // Align items to the left
//         gap: "10px", // Spacing between the controls
//       }}
//     >
//       <div
//         style={{
//           padding: "5%",
//           backgroundColor: "white",
//           borderRadius: "8px",
//           boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
//           fontSize: "clamp(12px, 2vw, 16px)", // Responsive font size
//         }}
//       >
//         {areaSqFt !== null ? `${areaSqFt} sqft` : "Draw a shape to see area"}
//       </div>

//       <button
//         onClick={toggleMapType}
//         style={{
//           padding: "5%",
//           backgroundColor: "green",
//           color: "white",
//           border: "none",
//           borderRadius: "5px",
//           cursor: "pointer",
//           fontSize: "clamp(12px, 2vw, 16px)", // Responsive font size
//         }}
//       >
//         Map
//       </button>
//     </div>
//    */}
//       <div
//         style={{
//           position: "relative",
//           bottom: "30px",
//           right: "20px",
//           padding: "10px",
//           backgroundColor: "white",
//           borderRadius: "8px",
//           boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
//           fontSize: "16px",
//           zIndex: "9999",
//         }}
//       >
//         {areaSqFt !== null ? `${areaSqFt} sqft` : "Draw a shape to see area"}
//       </div>

//       <button
//         onClick={toggleMapType}
//         style={{
//           position: "relative",
//           bottom: "80px",
//           right: "20px",
//           padding: "10px",
//           backgroundColor: "green",
//           color: "white",
//           border: "none",
//           borderRadius: "5px",
//           cursor: "pointer",
//           zIndex: "9999",
//         }}
//       >
//         Map
//       </button>
//     </div>
//   );
// }

// export default NewCrop;







import { useState, useRef, useMemo, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Polyline,
  useMapEvents,
  Marker,
  Tooltip,
  FeatureGroup,
  Polygon,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import L from "leaflet";
// import {ReactLeafletDistortableImage} from "@diwotech/leaflet-distortableimage"
const lat_adjust = 0.0000127979272
const long_adjust  = 0.00002413988112

const center1 = [33.668183, 73.074943];
const center2 = [33.668093, 73.074745];

function MapCornerLogger() {
  const map = useMapEvents({
    moveend: () => {
      const bounds = map.getBounds();
      const southwest = bounds.getSouthWest();
      const northeast = bounds.getNorthEast();

      console.log("Southwest Corner:", southwest);
      console.log("Northeast Corner:", northeast);
    },
    click: (e) => {
      const { lat, lng } = e.latlng;
      console.log("Clicked Lat, Lng:", lat, lng);
    },
  });

  return null;
}

function DraggableMarker({ position, setPosition }) {
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
        }
      },
    }),
    [setPosition]
  );

  return (
    <Marker
      draggable={true}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
    />
  );
}

function App() {
  const [marker1Pos, setMarker1Pos] = useState(center1);
  const [marker2Pos] = useState(center2);
  const [distance, setDistance] = useState(0);
  const [midpoint, setMidpoint] = useState(null);
  const [areaSqFt, setAreaSqFt] = useState(0);
  const featureGroupRef = useRef(null);
  const [mapType, setMapType] = useState("satellite"); // Default to satellite
  const [firstSwitch, setFirstSwitch] = useState(false);
  const tileLayerURL =
    mapType === "roadmap"
      ? "https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
      : "https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}";

  // const grayAreaCorners = useMemo(() => {
  //   const bounds = [
  //     [90, -180],  // Top-left corner of the map (world bounds)
  //     [90, 180],   // Top-right corner
  //     [-90, 180],  // Bottom-right corner
  //     [-90, -180], // Bottom-left corner
  //   ];

  //   let hole = [
  //     [37.32209796296457, -121.9568882882595], // Replace these with the desired coordinates
  //     [37.32209796296457, -121.95646718144418],
  //     [37.32189319477203, -121.95645913481715],
  //     [37.321891061767076, -121.9568856060505],
  //   ];
  //   if(mapType != 'roadmap'){
  //     console.log("ROADMAP")
  //     hole.forEach((value) =>{
  //       value = [value[0]+lat_adjust, value[1]-long_adjust]
  //     })
  //     setFirstSwitch(true)
  //   }
  //   else if(firstSwitch){
  //     console.log("mAP")
  //     hole.forEach((value) =>{
  //       value = [value[0]-lat_adjust, value[1]+long_adjust]
  //     })
      
  //   }
  //   return [bounds, hole];
  // }, []);
    const [hole, setHole] = useState([
      [37.32209796296457, -121.9568882882595],
      [37.32209796296457, -121.95646718144418],
      [37.32189319477203, -121.95645913481715],
      [37.321891061767076, -121.9568856060505],
      // [37.32235116752604, -121.95582975950721], [37.32225716752604, -121.9562717595072], [37.32228016752604, -121.95628075950721], [37.32228066752604, -121.95628125950721], [37.32230216752604, -121.95629175950721], [37.322303667526036, -121.9562927595072], [37.322326167526036, -121.9563062595072], [37.32234616752604, -121.9563217595072], [37.32234716752604, -121.9563222595072], [37.32236616752604, -121.9563402595072], [37.32236716752604, -121.95634075950721], [37.32238566752604, -121.9563607595072], [37.32243066752604, -121.95630775950721], [37.32245616752604, -121.9562432595072], [37.32249716752604, -121.95613975950721], [37.322506167526036, -121.95611725950721], [37.32253316752604, -121.95604975950721], [37.32248466752604, -121.9558312595072], [37.32246766752604, -121.95583075950721], [37.32235116752604, -121.95582975950721]
    ]);
    
    useEffect(() => {
      const updatedHole = hole.map((value) => {
        if (mapType !== 'roadmap' && !firstSwitch) {
          return [value[0] + lat_adjust, value[1] - long_adjust];
        } else if (mapType === 'roadmap' && firstSwitch) {
          return [value[0] - lat_adjust, value[1] + long_adjust];
        }
        return value;
      });
    
      setHole(updatedHole);
      setFirstSwitch(mapType !== 'roadmap');
    }, [mapType, firstSwitch]);
    
    const grayAreaCorners = useMemo(() => {
      const bounds = [
        [90, -180],
        [90, 180],
        [-90, 180],
        [-90, -180],
      ];
      return [bounds, hole];
    }, [hole]);
  useEffect(() => {
    const calculateDistance = () => {
      const point1 = L.latLng(marker1Pos);
      const point2 = L.latLng(marker2Pos);
      return point1.distanceTo(point2).toFixed(2);
    };

    const calculateMidpoint = () => [
      (marker1Pos[0] + marker2Pos[0]) / 2,
      (marker1Pos[1] + marker2Pos[1]) / 2,
    ];

    setDistance(calculateDistance());
    setMidpoint(calculateMidpoint());
    console.log("midpoint ", midpoint);
  }, [marker1Pos, marker2Pos]);

 
  const updateDistance = (newPos) => {
    setMarker1Pos(newPos);
  };

  const handleDrawCreated = (e) => {
    const layer = e.layer;
    const type = e.layerType;

    if (type === "polygon" || type === "rectangle") {
      const latLngs = type === "polygon" ? layer.getLatLngs()[0] : layer.getLatLngs();
      const areaMeters = L.GeometryUtil.geodesicArea(latLngs);
      const areaFeet = (areaMeters * 10.7639).toFixed(0);
      setAreaSqFt(areaFeet);
      setAreaSqFt(areaFeet);


      layer.on("editresize", () => {
        const resizedLatLngs = layer.getLatLngs();
        const resizedAreaMeters = L.GeometryUtil.geodesicArea(resizedLatLngs);
        const resizedAreaFeet = (resizedAreaMeters * 10.7639).toFixed(0);
        setAreaSqFt(resizedAreaFeet);
      });
    }
    
    layer.bindPopup("Shape created!").openPopup();
  };

  const handleDrawEdited = (e) => {
    e.layers.eachLayer((layer) => {
      if (layer instanceof L.Polygon || layer instanceof L.Rectangle) {
        const latLngs = layer instanceof L.Polygon ? layer.getLatLngs()[0] : layer.getLatLngs();
        const areaMeters = L.GeometryUtil.geodesicArea(latLngs);
        const areaFeet = (areaMeters * 10.7639).toFixed(0);
        setAreaSqFt(areaFeet);
      }
    });
  };

  const handleClearAll = () => {
    const layers = featureGroupRef.current.getLayers();
    layers.forEach((layer) => featureGroupRef.current.removeLayer(layer));
    setAreaSqFt(0);
  };

  const toggleMapType = () => {
    setMapType((prevMapType) => (prevMapType === "roadmap" ? "satellite" : "roadmap"));
  };

  return (
    <div style={{ display: "flex" ,width:"100%", height:"100%", position:"relative"}}>
      <MapContainer
        style={{
          height: "100vh",
          width: "100%",
        }}
        center={[37.32184906850928, -121.95668444021046]}
        zoom={21}
        maxZoom={30}
      >
        <TileLayer
          attribution="Google Maps"
          url={tileLayerURL}
          maxNativeZoom={28}
          maxZoom={30}
        />
        <MapCornerLogger />

        {/* Gray overlay with hole */}
        <Polygon positions={grayAreaCorners} pathOptions={{ color: "gray", fillOpacity: 0.7 }} />

        <DraggableMarker position={marker1Pos} setPosition={updateDistance} />
        <Marker position={marker2Pos} />

        <Polyline
          positions={[marker1Pos, marker2Pos]}
          pathOptions={{ color: "black", dashArray: "5, 10" }}
        >
          {midpoint && distance && (
            <Tooltip permanent position={midpoint}>
              Distance: {distance} meters
            </Tooltip>
          )}
        </Polyline>

        <FeatureGroup ref={featureGroupRef}>
          <EditControl
            position="topright"
            onCreated={handleDrawCreated}
            onEdited={handleDrawEdited}
            onDeleted={handleClearAll}
            draw={{
              polyline: false,
              circle: false,
              marker: false,
              polygon: {
                allowIntersection: false,
                shapeOptions: {
                  color: "#97009c",
                },
              },
              rectangle: true,
            }}
            edit={{
              remove: true,
            }}
          />
        </FeatureGroup>
      </MapContainer>

      <div
        style={{
          position: "absolute",
          bottom: "2%",
          right: "2%",
          padding: "10px",
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
          fontSize: "16px",
          zIndex: "9999",
        }}
      >
        {areaSqFt !== null ? `${areaSqFt} sqft` : "Draw a shape to see area"}
      </div>

      <button
        onClick={toggleMapType}
        style={{
          position: "absolute",
          bottom: "5%",
          left: "2%",
          padding: "10px",
          backgroundColor: "green",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          zIndex: "9999",
        }}
      >
        Map
      </button>
    </div>
  );
}

export default App;
